import React from "react";
import "./style.scss";
import NotiHeader from "@images/icon/noti-header.png";
import authAction from "@actions/auth";
import actionUsers from "@actions/users";
import crosstab from "crosstab";
import Language from "../../../../../components/Language";
import dataCacheProvider from "@data-access/datacache-provider";
import actionIntl from "@actions/intl";
import { connect, useDispatch } from "react-redux";
import { IntlActions, withTranslate } from "react-redux-multilingual";
import snackbar from "../../../../../utils/snackbar-utils";

function Index(props) {
  const { auth, translate } = props;
  const dispatch = useDispatch();
  const showModalChangePassword = () => {
    props.updateData({
      showChangePass: true,
    });
  };
  const changeIntl = (intl) => {
    dataCacheProvider.save("", "INTL-UPDATE-DATA", intl);
    props.updateDataIntl({
      locale: intl,
    });
    dispatch(IntlActions.setLocale(intl));
    snackbar.show(translate("tbchuyendoingonngu"), "success");
  };
  return (
    <div className={`admin-page ${props.className || ""}`} id={props.id || ""}>
      {props.widthWindow > 992 ? (
        <div className="wrap-header">
          {props.subheader && props.header && (
            <div className="subheader">
              {Array.isArray(props.icon) ? (
                props.icon[0]
              ) : (
                <img
                  src={props.icon}
                  alt=" "
                  onError={(i) => (i.target.style.display = "none")}
                />
              )}
              <h1 className="subheader-title">
                {props.header}
                {/* <i className={props.icon}></i>  */}
                {props.subheader && <small>{props.subheader}</small>}
              </h1>
            </div>
          )}
          <div className="wrap-login">
            <div>
              <img src={NotiHeader} className="image-icon"></img>
            </div>
            <div className="divider"></div>
            <div className="name-auth">{auth.donViTen}</div>
            <div className="logp-auth">
              <a
                href="#"
                data-toggle="dropdown"
                title={(props.auth || {}).email}
                className="header-icon d-flex align-items-center justify-content-center ml-2"
              >
                <img
                  src={
                    props.auth && props.auth.donViLogo
                      ? props.auth.donViLogo.absoluteFileUrl()
                      : "/img/demo/avatars/avatar-admin.png"
                  }
                  className="profile-image rounded-circle"
                  alt={(props.auth || {}).full_name}
                />
              </a>
              <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                  <div
                    className="d-flex flex-row align-items-center mt-1 mb-1 color-white"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="mr-2">
                      <img
                        src={
                          props.auth && props.auth.donViLogo
                            ? props.auth.donViLogo.absoluteFileUrl()
                            : "/img/demo/avatars/avatar-admin.png"
                        }
                        className="rounded-circle profile-image"
                        alt={(props.auth || {}).full_name}
                      />
                    </span>
                    <div className="info-card-text">
                      <div className="fs-lg text-truncate text-truncate-lg">
                        {(props.auth || {}).full_name}
                      </div>
                      <span className="text-truncate text-truncate-md opacity-80">
                        {(props.auth || {}).email}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider m-0"></div>
                <a href="#" className="dropdown-item" data-action="app-reset">
                  {/* <span data-i18n="drpdwn.reset_layout">Reset Layout</span> */}
                  <span data-i18n="drpdwn.reset_layout">
                    {translate("datlaibocuc")}
                  </span>
                </a>
                <a
                  href="#"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target=".js-modal-settings"
                >
                  {/* <span data-i18n="drpdwn.settings">Settings</span> */}
                  <span data-i18n="drpdwn.settings">{translate("caidat")}</span>
                </a>
                <div className="dropdown-divider m-0"></div>
                <a
                  href="#"
                  className="dropdown-item"
                  data-action="app-fullscreen"
                >
                  {/* <span data-i18n="drpdwn.fullscreen">Fullscreen</span> */}
                  <span data-i18n="drpdwn.fullscreen">
                    {translate("toanmanhinh")}
                  </span>
                  <i className="float-right text-muted fw-n">F11</i>
                </a>
                <a href="#" className="dropdown-item" data-action="app-print">
                  {/* <span data-i18n="drpdwn.print">Print</span> */}
                  <span data-i18n="drpdwn.print">{translate("in")}</span>
                  <i className="float-right text-muted fw-n">Ctrl + P</i>
                </a>
                <div className="dropdown-divider m-0"></div>
                {props.auth && props.auth.id ? (
                  <a
                    href="#"
                    onClick={() => {
                      showModalChangePassword();
                    }}
                    className="dropdown-item fw-500 pt-3 pb-3"
                  >
                    <span data-i18n="drpdwn.page-logout">
                      {translate("doimatkhau")}
                    </span>
                  </a>
                ) : null}
                {props.auth && props.auth.id ? (
                  <a
                    onClick={() => {
                      localStorage.clear();
                      props.updateDataAuth({
                        auth: {},
                      });
                      crosstab &&
                        crosstab.broadcast("message", {
                          event: "ivisitor_logout",
                        });
                      window.location.href = "/login";
                    }}
                    href="#"
                    className="dropdown-item fw-500 pt-3 pb-3"
                  >
                    <span data-i18n="drpdwn.page-logout">
                      {translate("dangxuat")}
                    </span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      localStorage.clear();
                      props.updateDataAuth({
                        auth: {},
                      });
                      window.location.href = "/login";
                    }}
                    href="#"
                    className="dropdown-item fw-500 pt-3 pb-3"
                  >
                    <span data-i18n="drpdwn.page-logout">
                      {translate("dangnhap")}
                    </span>
                  </a>
                )}
              </div>
            </div>
            <div className="logp-auth"  >
              <Language 
                firstName="Eng"
                lastName="Vie"
                onChangeBack={(e) => changeIntl(e)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {props.children}
    </div>
  );
}
export default connect(
  (state) => {
    return {
      auth: (state.auth && state.auth.auth) || {},
      widthWindow: state.auth.widthWindow,
    };
  },
  {
    updateData: actionUsers.updateData,
    updateDataAuth: authAction.updateData,
    updateDataIntl: actionIntl.updateData,
  }
)(withTranslate(Index));
